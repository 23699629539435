import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";


class Person extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {
        let contact;
        let email;
        if (this.props.data.contact.email || this.props.data.contact.email !== "") {
            email = this.props.data.contact.email.split('@');
            email = `${email[0]}<wbr>@${email[1]}`;
        } else {
            email = this.props.data.contact.email;
        }
        if (this.props.contact)
            contact = <div>
                <div className={'t-12 pt-10'}>{this.props.data.contact.phone}</div>
                <div className={`t-12 pt-10`}>
                    <a aria-label={'Email'} className={`${this.props.color} block lh-100`} href={`mailto:${this.props.data.contact.email}`} dangerouslySetInnerHTML={{ __html: email }}/>
                </div>
            </div>

        return (
            <>
                <div className={`f-container f-justify-center full-height ${this.props.color}`}>
                    <div className={'f-container f-column f-justify-center'}>
                        <div className={'w-150 align-center f-center m-20'}>
                            <img width={100} height={100} className={"w-auto h-100 circle"} src={this.props.data.image} alt={this.props.data.firstName + ' ' + this.props.data.lastName}/>
                            <div className={'bold mb-10 lh-120'}>{this.props.data.firstName + ' ' + this.props.data.lastName}</div>
                            {this.props.function && <div className={'t-12 lh-100'}>{this.props.data.function}</div>}
                            {contact}
                            {this.props.data.contact.linkedin && <div className={'mt-5'}>
                                <a aria-label={'Linkedin'} className={this.props.color} href={`${this.props.data.contact.linkedin}`}><FontAwesomeIcon className={'ml-5 mr-5 t-18'} icon={faLinkedin} /></a>
                            </div>}
                            {this.props.data.contact.link && <div className={'mt-5'}>
                                <a aria-label={'Website'} className={this.props.color} href={`${this.props.data.contact.link}`}><FontAwesomeIcon className={'ml-5 mr-5 t-18'} icon={faLink} /></a>
                            </div>}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Person
