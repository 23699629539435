import { Link } from "gatsby"
import React, { Component } from "react"

class SingleLine extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {
        const buttonColor = this.props.backgroundColor.indexOf('blue') > -1 ? 'white-button' : 'blue-button';
        const links = this.props.data.links ? <div>
            {this.props.data.links.map((link, index) => <Link key={index} to={`/${link.replace(/ /g, '-').toLowerCase()}`}
                                                               className={`mt-30 ${buttonColor}`}>Meer {link.toLowerCase()}
            </Link>)}
        </div> : ""

        return (
            <>
                <div className={'light-grey'}>
                <div className={'f-container f-justify-center container small'}>
                    <div className={"align-center m-50"}>
                        <div className={`align-center bold t-${this.props.fontSize}`} dangerouslySetInnerHTML={{ __html: this.props.data.text }} />
                        {links}
                    </div>
                </div>
                </div>
            </>
        )
    }
}

export default SingleLine
