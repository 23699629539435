import React from "react"
import 'moment/locale/nl';
import {Helmet} from "react-helmet";

const HelmetContainer = ({meta}) =>  <Helmet>
    <title>{meta.title}</title>
    <meta name="description" content={meta.description} />
</Helmet>


export default HelmetContainer
