import React, { Component } from "react"
import ParallaxBackground from "../parallax";
import Person from "../person";
import data from "../../data/nl.people";
import _ from "lodash";

class PersonAndText extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {

        const personColumn = <div className={"col-6 split f-container f-justify-center mt-40"}>
            {this.props.block.managers.map(person => <Person function={true} color={this.props.textColor} data={data.people.find(x => x.name === person)} contact={true} />)}
        </div>;

        const textColumn = <div className={`col-6 p-12 pb-40 pt-40 ${this.props.textColor}`}>{this.props.block.header ? <h2 className={"bold t-34 lh-105 mb-20"}>{this.props.block.header}{_.last(this.props.block.header) !== '?' ? '.' : ''}</h2> : ''}
            <div className={"lh-150"} dangerouslySetInnerHTML={{ __html: this.props.block.text }}/>{this.props.children}</div>;

        const content = this.props.textSide === "left" ?
            <div id={this.props.block.header.replace(/ /g, '-').toLowerCase()} className={'f-container split container small'}>{textColumn}{personColumn}</div> :
            <div id={this.props.block.header.replace(/ /g, '-').toLowerCase()} className={'f-container split container small'}>{personColumn}{textColumn}</div>;

        return (
            <>
                <ParallaxBackground backgroundClass={`full-width ${this.props.backgroundColor}`} circle={this.props.circle}>
                    {content}
                </ParallaxBackground>
            </>
        )
    }
}

export default PersonAndText
