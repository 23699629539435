import * as React from "react"
import Layout from "../components/layout"
import MainBlock from "../components/blocks/main";
import ImageAndTextScaling from "../components/blocks/image-text-scaling";
import TextAndText2headers from "../components/blocks/text-text-2headers";
import Sitemap from "../components/blocks/sitemap";
import coverImage from "../images/headers/header_mendixworld.png";
import data from "../data/pages/nl.mendixworld";
import VideoAndText from "../components/blocks/video-text";

import Helmet from "../components/helmet";
import DemoRequest from "../components/blocks/DemoRequest";
import SingleLine from "../components/blocks/single-line";
import PersonAndText from "../components/blocks/person-text";
import Contact from "../components/blocks/contact-block";

// markup
const MendixWorldPage = () => {


    return (
        <Layout>
            <Helmet meta={data.pageData.meta} />
            <MainBlock id={"mainCover"} cover={coverImage} type={"sub"} jumbotron={false} bpx={65} bpy={70}>
            </MainBlock>
            <SingleLine backgroundColor={'blue-1'} data={data.pageData.blockHeader} fontSize={32} />
            <ImageAndTextScaling block={data.pageData.register} textSide={"left"} backgroundColor={'pure-white'} textColor={'deep-blue-text'}>
                <a href={'https://bit.ly/3iEx68x'} rel="noreferrer" target={'_blank'} className={'blue-button mt-40'}>Aanmelden</a>
            </ImageAndTextScaling>
            <PersonAndText block={data.pageData.tijd} textSide={"left"} backgroundColor={'blue'} textColor={'white-text'}>
                <p>
                    <DemoRequest backgroundColor={'blue'} textColor={'white-text'} event={"Mendix World 2021"} date={false} contact={data.pageData.tijd.managers[0]}/>
                </p>
            </PersonAndText>
            <VideoAndText controls={false} block={data.pageData.programma} textSide={"left"} backgroundColor={'pure-white'} textColor={'deep-blue-text'} />
            <TextAndText2headers block={data.pageData.programma2} backgroundColor={'pure-white'} textColor={'deep-blue-text'} circle={true} />
            <ImageAndTextScaling block={data.pageData.programma3} textSide={"left"} backgroundColor={'pure-white'} textColor={'deep-blue-text'} >
                <a href={'https://bit.ly/3iEx68x'} rel="noreferrer" target={'_blank'} className={'blue-button mt-40'}>Meer Mendix World</a>
            </ImageAndTextScaling>
            <Contact block={data.pageData.contactList} page={data.pageData.jumbotron.title} backgroundColor={'blue-1'} textColor={'white-text'} />
            <Sitemap/>
        </Layout>
    )
}

export default MendixWorldPage
