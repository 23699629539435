import React from "react"

const Jumbotron = ({children, title, subtitle, bpx, bpy, light}) => (
    <div className={`jumbotron ${light ? 'lightShadow' : 'darkShadow'} column f-container f-column f-justify-center col-12 pl-0 mw-600 pb-100 pt-100 bpx-${bpx} bpy-${bpy}`}>
        <h1 className="t-70 white-text bold lh-100 relative">
            <span dangerouslySetInnerHTML={{ __html: `${title}.` }}/>
            <span className="block t-24 mt-20 bold" dangerouslySetInnerHTML={{ __html: `${subtitle}` }}/>
        </h1>
        {children}
    </div>
)

export default Jumbotron;
