import register from "../../images/mendix-world/MXW Register.webp";
import tagline from "../../images/mendix-world/Tagline.webp"
import globe from "../../images/mendix-world/videocover.webp"
import video from "../../images/mendix-world/Dots_Loop_V2-vfast.mp4";


export default {
    pageData: {
        meta: {
            title: "Mendix World 2021",
            description: "Mendix World is de grootste virtuele bijeenkomst van ontwikkelaars, managers en IT-leiders die met low-code betere oplossingen ontwikkelen."
        },
        jumbotron: {
            title: "Mendix World 2021",
            subtitle: "Go make it",
            callToAction: "",
            callToActionLink: "",
        },
        blockHeader: {
            text: "“Bring your app development A-team together at Mendix World!”"
        },
        register: {
            image: {
                image: register,
                alt: "Register now"
            },
            header: "Aanmelden",
            text: "Mendix World is de grootste virtuele bijeenkomst van ontwikkelaars, managers en IT-leiders die door middel van low-code betere oplossingen ontwikkelen. In drie dagen zie je hoe low-code je kan helpen om de juiste ideeën, teams en applicaties samen te brengen om het verschil te maken in een steeds digitaler wordende wereld." +
                "<p>Het event vindt plaats van 7 t/m 9 september 2021. Wij zijn er zeker bij, jij ook? Meld je nu via DDC aan voor gratis toegang!</p>",
        },
        tijd: {
            header: "Geen tijd?",
            text: "Komt het niet uit om zelf alle sessies bij te wonen?" +
                "<p>Laat hier je e-mailadres achter en wij houden je op de hoogte van alle highlights!</p>",
            managers: [
                'Maurice Gelden'
            ]
        },
        programma: {
            header: "Programma",
            image: {
                image: globe,
                alt: "Makers Assemble"
            },
            text: "Er valt tijdens de low-codedagen van 9.00 tot 12.00 uur van alles te kiezen. Op de website van Mendix World kan je zien welke sessies georganiseerd worden en wat verder de mogelijkheden zijn. ",
            video: video
        },
        programma2: {
            headerLeft: "Dinsdag",
            headerRight: "Woensdag",
            textLeft: "<ul>" +
                "<li>De dag wordt geopend door Derek Roos, Mendix Co-founder & CEO.</li>" +
                "<li>Low-code download: meer dan 85 sessies, dus voor ieder wat wils.</li>" +
                "<li>Meet the makers: doe mee aan kleine groepssessies of creëer je eigen groep.</li>" +
                "<li>Howdy, partners: schud virtueel de handen met een aantal van de grootste low-code partners van de wereld.</li>" +
                "<li>Eindig de dag met special guest Neri Oxman (TBA July).</li>" +
                "</ul>",
            textRight: "<ul>" +
                "<li>Begin de tweede dag met keynotespeaker Johan den Haan, Mendix CTO en een aantal speciale gasten.</li>" +
                "<li>Low-code download: meer dan 85 sessies, dus voor ieder wat wils.</li>" +
                "<li>Meet the makers: doe mee aan kleine groepssessies of creëer je eigen groep.</li>" +
                "<li>Howdy, partners: schud virtueel de handen met een aantal van de grootste low-code partners van de wereld.</li>" +
                "<li>De dag eindigt met ‘Live AMA: Mendix Product Roadmap’ met Johan den Haan, Mendix CTO.</li>" +
                "</ul>"
        },
        programma3: {
            image: {
                image: tagline,
                alt: "Makers Assemble"
            },
            header: "Donderdag",
            text: "<ul>" +
                "<li>De ‘keynote to end all keynotes’ is bekend: Simon Sinek</li>" +
                "<li>Low-code download: meer dan 85 sessies, dus voor ieder wat wils.</li>" +
                "<li>Meet the makers: doe mee aan kleine groepssessies of creëer je eigen groep.</li>" +
                "<li>Howdy, partners: schud virtueel de handen met een aantal van de grootste low-code partners van de wereld.</li>" +
                "<li>Het event wordt feestelijk afgesloten met de ‘final countdown’.</li>" +
                "</ul>"
        },
        contactList: [
            'Maurice Gelden'
        ]
    },
}
