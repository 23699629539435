import React, { Component } from "react"
import ParallaxBackground from "../parallax";
import {Link} from "gatsby";

class TextAndText2headers extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {

        const buttonColor = this.props.backgroundColor.indexOf('blue') > -1 ? 'white-button' : 'blue-button';
        const links = this.props.block.links ? <div>
            {this.props.block.links.map((link, index) => <Link key={index} to={`/${link.replace(/ /g, '-').toLowerCase()}`}
                                                               className={`mt-30 ${buttonColor}`}>Meer {link.toLowerCase()}
            </Link>)}
        </div> : ""

        const textColumnLeft = <div id={this.props.block.headerLeft.replace(/ /g, '-').toLowerCase()} className={`col-6 p-12 pt-20`}>
            {this.props.block.headerLeft ? <h2 className={"bold t-34 lh-105 mb-20"} dangerouslySetInnerHTML={{ __html: this.props.block.headerLeft }} /> : ''}
            <div className={"lh-150"} dangerouslySetInnerHTML={{ __html: this.props.block.textLeft }}/>
        </div>;

        const textColumnRight = <div id={this.props.block.headerRight.replace(/ /g, '-').toLowerCase()} className={`col-6 p-12 pt-20`}>
            {this.props.block.headerRight ? <h2 className={"bold t-34 lh-105 mb-20"} dangerouslySetInnerHTML={{ __html: this.props.block.headerRight }} /> : ''}
            <div className={"lh-150"} dangerouslySetInnerHTML={{ __html: this.props.block.textRight }}/>{links}{this.props.children}
        </div>;

        return (
            <>
                <ParallaxBackground backgroundClass={`full-width ${this.props.backgroundColor}`} circle={this.props.circle}>
                    <div className={`container split small pt-40 pb-40 ${this.props.textColor}`}>
                        <div className={'f-container'}>
                            {textColumnLeft}
                            {textColumnRight}
                        </div>
                    </div>
                </ParallaxBackground>
            </>
        )
    }
}

export default TextAndText2headers
