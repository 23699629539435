import React, { Component } from "react"
import ParallaxBackground from "../parallax";
import Person from "../person";
import data from "../../data/nl.people";
import {Link} from "gatsby";
import axios from "axios"

class Contact extends Component {

    constructor(props) {
        super(props)
        this.props = props;
        this.state = {
            emailAddress: "",
            subject: props.page ? props.page : "",
            validations: {
                emailAddress: true,
            },
            success: null,
            loading: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        const personColumn = <div className={"col-6 f-container f-justify-center mt-40"}>
            {this.props.block.map((person, index) => <Person key={index} color={this.props.textColor} data={data.people.find(x => x.name === person)} contact={true} />)}
        </div>;

        const buttonColor = this.props.backgroundColor.indexOf('blue') > -1 ? 'white-button' : 'blue-button';

        return (
            <>
                <ParallaxBackground backgroundClass={`full-width ${this.props.backgroundColor}`} circle={this.props.circle}>
                    <div id={'contact'} className={`f-container split container small p-12 pb-40 pt-40 ${this.props.textColor}`}>
                        <div className={'col-6 f-container f-column f-justify-center align-center'}>
                            <div className={'bold t-30 lh-105 mb-10'}>Meer weten?</div>
                            <div className={'t-18 lh-105 mb-20'}>Neem dan nu contact op met {data.people.find(x => x.name === this.props.block[0]).firstName}</div>
                            <div className={'t-18 lh-105 mb-20'}>Of laat je emailadres achter, dan nemen wij contact met jou op.</div>
                            <div className={this.state.validations.emailAddress ? "valid" : "invalid"}>
                                <input
                                    className={`full-width p-8 borderless col-8 ${this.props.backgroundColor !== 'white' ? 'white-transparent white-text white' : 'very-dark-blue-transparent'}`}
                                    id={'e-mailadres:'}
                                    name="emailAddress"
                                    onChange={this.handleChange}
                                    value={this.state.emailAddress}
                                    placeholder={'e-mailadres:'}
                                    type="emailAddress"
                                />
                                <button
                                    className={this.props.backgroundColor !== 'white' ? 'white-button' : 'blue-button'}
                                    onClick={this.handleSubmit}
                                >
                                    Verzend
                                </button>
                            </div>
                            <Link to={`/contact`}
                                  className={`mt-40 f-self-center ${buttonColor}`}>
                                Contact
                            </Link>
                        </div>
                        {personColumn}
                    </div>
                </ParallaxBackground>
            </>
        )
    }

    handleChange(e) {
        const name = e.target.name
        const value = e.target.value
        this.setState({ [name]: value })
    }

    handleSubmit() {
        let hasErrors = false;
        const validations = {
            emailAddress: true,
        }
        if (this.state.emailAddress === "") {
            validations.emailAddress = false
            hasErrors = true;
        }
        if (!/\S+@\S+\.\S+/.test(this.state.emailAddress)) {
            validations.emailAddress = false
            hasErrors = true;
        }

        if (hasErrors) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    validations: {
                        emailAddress: validations.emailAddress,
                    },
                }
            })
        } else {

            this.setState(prevState => {
                return {
                    ...prevState,
                    loading: true
                };
            });

            const bodyFormData = new FormData();
            bodyFormData.set('emailAddress', this.state.emailAddress)
            bodyFormData.set('subject', this.state.subject)

            axios({
                method: 'post',
                url: 'https://ddc.bettywebblocks.com/website/addcontactform',
                data: bodyFormData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } }
            }).then(() => {
                alert('Bedankt voor je interesse. Wij nemen zo spoedig mogelijk contact op.')
                this.setState(() => {
                    return {
                        emailAddress: "",
                        subject: this.props.page ? this.props.page : "",
                        success: true,
                        loading: false
                    }
                })
            }).catch(err => {
                console.log(err)
            });
        }
    }
}

export default Contact
