import text from "../../data/nl"
import * as React from "react"
import axios from "axios"
import { graphql, useStaticQuery } from "gatsby";
import {useState} from "react";
import people from "../../data/nl.people";

const DemoRequest = (props) => {
  const [state, setState] = useState({
    name: "",
    emailAddress: "",
    phone: "",
    date: "",
    validations: {
      name: true,
      date: true,
      emailAddress: true,
      phone: true,
    },
    success: null,
    loading: false,
  });


  const cmsData = useStaticQuery(query);

  const handleChange = (e) => {

    const name = e.target.name
    const value = e.target.value
    setState(prevState => {
      return {
        ...prevState,
        [name]: value
      }
    });
  }

  const handleSubmit = () => {
    let hasErrors = false

    const validationsCheck = {
      date: true,
      name: true,
      emailAddress: true,
      phone: true,
    }

    if (state.name === "") {
      validationsCheck.name = false
      hasErrors = true
    }
    if (state.emailAddress === "") {
      validationsCheck.emailAddress = false
      hasErrors = true
    }
    if (!/\S+@\S+\.\S+/.test(state.emailAddress)) {
      validationsCheck.emailAddress = false
      hasErrors = true
    }

    if (props.phone) {
      if (state.phone === "") {
        validationsCheck.phone = false
        hasErrors = true
      }
    }

    if (props.date) {
      if (state.date === "") {
        validationsCheck.date = false
        hasErrors = true
      }
    }

    if (hasErrors) {
      setState(prevState => {
        return {
          ...prevState,
          validations: {
            name: validationsCheck.name,
            emailAddress: validationsCheck.emailAddress,
            date: validationsCheck.date,
            phone: validationsCheck.phone,
          },
        }
      })
    } else {

      setState(prevState => {
        return {
          ...prevState,
          loading: true
        }
      });

      const bodyFormData = new FormData()
      const contactPerson = people.people.find(x => x.name === props.contact)
      bodyFormData.set("name", state.name)
      bodyFormData.set("emailAddress", state.emailAddress)
      bodyFormData.set("date", state.date)
      bodyFormData.set("phone", state.phone)
      bodyFormData.set("event", props.event)
      bodyFormData.set("contact", contactPerson.contact.email)
      bodyFormData.set("contactName", props.contact)

      axios({
        method: "post",
        url: "https://ddc.bettywebblocks.com/website/EventRegister",
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      }).then(() => {
        setState({
          date: "",
          name: "",
          emailAddress: "",
          phone: "",
          validations: {
            date: true,
            name: true,
            emailAddress: true,
            phone: true,
          },
          success: true,
          loading: false,
        })
      }).catch(err => {
        console.log(err)
      })
    }
  }

  return <div className="contact-form hbox" id="ContactForm">
    <div className="full-width">
      {props.date && <div>
        <label className="block bold mb-12" htmlFor="date">Datum *</label>
        <div className={state.validations.date ? "valid" : "invalid-radio"}>
          {
            cmsData.allStrapiDemoDatums.nodes.map(node => node.event.Name === props.event && <label className={'block'}>
              <input
                  name={"date"}
                  value={node.Datum}
                  checked={node.Datum === state.date}
                  onChange={handleChange}
                  type={'radio'}
              /> {node.Datum}
            </label>)
          }
        </div>
      </div>}
      <div className={state.validations.name ? "valid" : "invalid"}>
        <input
            className={`full-width p-8 borderless col-8 ${props.backgroundColor !== 'white' ? 'white-transparent white-text white' : 'very-dark-blue-transparent'}`}
            id={text.contactBlock.form.name}
            placeholder={text.contactBlock.form.name}
            name="name"
            value={state.name}
            onChange={handleChange}
            type="text"
        />
      </div>
      <div className={state.validations.emailAddress ? "valid" : "invalid"}>
        <input
            className={`full-width p-8 borderless col-8 ${props.backgroundColor !== 'white' ? 'white-transparent white-text white' : 'very-dark-blue-transparent'}`}
            id={text.contactBlock.form.emailAddress}
            placeholder={text.contactBlock.form.emailAddress}
            name="emailAddress"
            value={state.emailAddress}
            onChange={handleChange}
            type="text"
        />
      </div>
      {props.phone && <div className={state.validations.phone ? "valid" : "invalid"}>
        <input
            className={`full-width p-8 borderless col-8 ${props.backgroundColor !== 'white' ? 'white-transparent white-text white' : 'very-dark-blue-transparent'}`}
            id={text.contactBlock.form.phone}
            placeholder={text.contactBlock.form.phone}
            name="phone"
            value={state.phone}
            onChange={handleChange}
            type="text"
        />
      </div>}
    </div>
    <div className="full-width">

      <button
          className={`${props.backgroundColor !== 'white' ? 'white-button' : 'blue-button'} mt-20`}
          onClick={handleSubmit}
      >
        {text.contactBlock.form.submit}
      </button>
    </div>
  </div>
}


const query = graphql`
  query demoDatums {
    allStrapiDemoDatums {
      nodes {
        Datum
        event {
          Name
        }
      }
    }
  }
`;

export default DemoRequest
