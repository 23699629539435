import { Link } from "gatsby"
import React, { Component } from "react"
import Logo from "../../images/Logo_ddc_white.png";
import ParallaxBackground from "../parallax";
import menuData from "../../data/nl.menu";
import data from "../../data/nl";

class Sitemap extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {

        let oplossingen = <div className={'f-grow-3'}>
            <div className={'bold t-16 mb-10'}>
                <Link className={'hover_u'} to={menuData.menu[0].url}>{menuData.menu[0].name}</Link>
            </div>
            <div className={'f-container f-column f-wrap h-550 sitemap-container'}>
                {
                    menuData.menu[0].subitems.map((item, index) => {
                        return <div key={index} className={'col-6'}>
                            <div className={'t-14 bold lh-110 pb-5'}>
                                <Link className={'hover_u'} to={item.url} dangerouslySetInnerHTML={{ __html: item.name }}/>
                            </div>
                            <div className={'mb-10'}>
                                {item.subitems.map((item, index) => {
                                    return <div key={index} className={'t-14 lh-110 pb-10'}>
                                        <Link className={'hover_u'} to={item.url} dangerouslySetInnerHTML={{ __html: item.name }}/>
                                    </div>
                                })}
                            </div>
                        </div>
                    })
                }
            </div>
        </div>

        let madeBy = <div className={'f-grow-1'}>
            <div className={'bold t-16 mb-10'}>
                <Link className={'hover_u'} to={menuData.menu[1].url}>{menuData.menu[1].name}</Link>
            </div>
            <div className={'f-container f-column f-wrap h-550'}>
                {
                    menuData.menu[1].subitems.map((item, index) => {
                        return <div key={index} className={'col-12'}>
                            <div className={'t-14 bold lh-110'}>
                                <Link className={'hover_u'} to={item.url}>{item.name}</Link>
                            </div>
                            <div className={'mb-10'}>
                                {item.subitems.map((item, index) => {
                                    return <div key={index} className={'t-14 lh-110'}>
                                        <Link className={'hover_u'} to={item.url}>{item.name}</Link>
                                    </div>
                                })}
                            </div>
                        </div>
                    })
                }
            </div>
        </div>


        let contact = <div>
            <div className={'bold t-16 mb-10'}>
                <Link className={'hover_u'} to={menuData.menu[2].url}>{menuData.menu[2].name}</Link>
            </div>
            <div className={'bold t-16 mb-10'}>
                <Link className={'hover_u'} to={menuData.menu[3].url}>{menuData.menu[3].name}</Link>
            </div>
            <div className={'bold t-16 mb-10'}>
                <Link className={'hover_u'} to={menuData.menu[4].url}>{menuData.menu[4].name}</Link>
            </div>
            <div className={'bold t-16 mb-10 pt-20'}>
                <Link className={'hover_u'} to={menuData.menu[5].url}>{menuData.menu[5].name}</Link>
            </div>

            <div className={'bold t-14'}>
                <div className={'mb-5 hover_u'}><a href={`mailto:${data.companyInfo.email}`}>{data.companyInfo.email}</a></div>
                <div className={'mb-5 hover_u'}><a href={`tel:${data.companyInfo.phone}`}>{data.companyInfo.phone}</a></div>
                <div>{data.companyInfo.office}</div>
                <div>{data.companyInfo.address}</div>
                <div>{data.companyInfo.postcode}</div>
            </div>
            <div className={'bold t-16 mb-10 pt-20'}>
                <Link className={'hover_u'} to={'/privacyverklaring'}>Privacyverklaring</Link>
            </div>
        </div>

        return (
            <>
                <ParallaxBackground backgroundClass={'blue full-width white-text'} circle={true}>
                    <div className={'full-width white-text'}>
                        <div className={'container small p-12 pb-40 pt-60'}>
                            <img width={200} height={93} src={Logo} alt={"DDC Logo"} />
                            <div className={"f-container mt-40 sitemap f-wrap"}>
                                {oplossingen}
                                {madeBy}
                                {
                                    // menuData.menu.map((mainItem, mainIndex) => {
                                    //     let grow = (mainIndex === 0) ? 'f-grow-3' : 'pl-10 pr-10';
                                    //     return <div></div>
                                    //
                                    // })
                                    contact
                                }
                            </div>
                        </div>
                    </div>
                    <script
                        dangerouslySetInnerHTML={{
                            __html: `(function(){ window.ldfdr = window.ldfdr || {}; (function(d, s, ss, fs){ fs = d.getElementsByTagName(s)[0]; function ce(src){ var cs = d.createElement(s); cs.src = src; setTimeout(function(){fs.parentNode.insertBefore(cs,fs)}, 1); } ce(ss); })(document, 'script', 'https://sc.lfeeder.com/lftracker_v1_bElvO73qbJGaZMqj.js'); })();`,
                        }}
                    />
                </ParallaxBackground>
            </>
        )
    }
}

export default Sitemap
