import React, { Component } from "react"
import ParallaxBackground from "../parallax";
import {Link} from "gatsby";

class VideoAndText extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {
        const buttonColor = this.props.backgroundColor.indexOf('blue') > -1 ? 'white-button' : 'blue-button';
        const links = this.props.block.links ? <div>
            {this.props.block.links.map((link, index) => <Link key={index} to={`/${link.replace(/ /g, '-').toLowerCase()}`}
                                                         className={`mt-30 ${buttonColor}`}>Meer {link.toLowerCase()}
            </Link>)}
        </div> : ""

        const imageColumn = <div className={`col-6 block image ${this.props.padding ? this.props.padding : ''}`}>
            {this.props.controls ? <video className="background-video full-width full-height" muted src={this.props.block.video} poster={this.props.block.image.image} controls/> : <video className="background-video full-width full-height" muted src={this.props.block.video} poster={this.props.block.image.image} autoPlay loop/>}
        </div>;
        const textColumn = <div className={`col-6 p-12 pb-40 pt-40 ${this.props.textColor}`}>
            {this.props.block.header ? <h2 className={"bold t-34 lh-105 mb-20"} dangerouslySetInnerHTML={{ __html: this.props.block.header }} /> : ''}
            <div className={"lh-150"} dangerouslySetInnerHTML={{ __html: this.props.block.text }}/>
            {links}
            {this.props.children}
        </div>;

        const content = this.props.textSide === "left" ? <div className={'f-container'}>{textColumn}{imageColumn}</div> :
            <div className={'f-container'}>{imageColumn}{textColumn}</div>;

        return (
            <>
                <ParallaxBackground backgroundClass={`full-width ${this.props.backgroundColor}`} circle={this.props.circle}>
                    <div className={'container small split'}>
                        {content}
                    </div>

                </ParallaxBackground>
            </>
        )
    }
}

export default VideoAndText
